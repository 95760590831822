<template>
  <div class="w-100 position-relative d-flex flex-column align-items-center wrapper bg-transparent-dark">
    <div class="cover" :style="{ 'background-image': `url(${resizeUpload(cover, '380h')})` }" />

    <!-- <img loading="lazy" v-image class="profile position-absolute" :src="profilePicture" /> -->

    <div class="name d-flex mt-4 mb-2">{{ name }}</div>
    <div v-if="showBack" class="name d-flex mb-3">
      <router-link :to="{ name: 'world-details', params: { slug: world.slug } }" class="edit clickable-item-hov"
        >Back to World</router-link
      >
    </div>
    <div v-if="status.role === worldMemberRoles.leader && world" class="name d-flex mb-3">
      <router-link :to="{ name: 'edit-world', params: { id: world.id } }" class="edit clickable-item-hov mr-4"
        >Edit</router-link
      >
      <router-link :to="{ name: 'manage-world', params: { id: world.id } }" class="edit clickable-item-hov"
        >Manage</router-link
      >
    </div>
  </div>
  <div v-if="world.is_nsfw" class="d-flex flex-column align-items-center p-2">
    <ion-badge color="danger">NSFW</ion-badge>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { WorldsInfo } from '@/shared/types/static-types';
import constants from '@/shared/statics/constants';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'WorldHeader',
})
export default class WorldHeader extends Vue {
  @Prop() world!: WorldsInfo;
  @Prop({ required: false, default: () => ({}) }) status: any = {};
  @Prop({ required: false }) showBack: boolean = false;

  resizeUpload = resizeUpload

  public worldMemberRoles = constants.worldMemberRoles;

  public get cover() {
    if (!this.world) return '/empty.png';
    return this.world.cover_image;
  }

  public get profilePicture() {
    // if (!this.world || !this.world.cropped_profile_img) return '/world-placeholder.png';
    // return this.world.cropped_profile_img;
    return '';
  }

  public get name() {
    return this.world.name;
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  padding-bottom: 100px
  .cover
    height: 240px
    width: 100%
    background-position: center
    background-size: cover
    background-repeat: no-repeat
  .profile
    width: 200px
    height: 200px
    top: 150px
    object-fit: cover
    border-radius: 12px
  .name
    position: relative
    font-size: 24px
    font-weight: bold
    top: 100px
    margin-top: 5px
    .edit
      font-size: 14px
      color: #03a9f4
      font-weight: bold
</style>
