<template>
  <ion-page class="page scrollable no-padding">
    <div>
      <world-header v-if="!isEmpty(world)" :world="world" :show-back="true"></world-header>
      <div class="wrapper py-2 px-4 bg-transparent-dark">
        <div class="secion mt-4">
          <div class="title mt-4 mb-2">Requests To Join</div>
          <p v-if="!get(userRequests, 'length')">No pending requests.</p>
          <div v-for="(user, index) in userRequests" :key="index">
            <div class="d-flex align-items-center">
              <router-link
                :to="{
                  name: 'profile',
                  params: { username: user.requestee.username },
                }"
              >
                <div class="name">{{ user.requestee.username }}</div>
              </router-link>
              <div class="ml-auto">
                <ion-button :disabled="isUserReqUpdating" class="approve-btn" @click="approveUserRequest(user.id)">
                  <ChLoading size="sm" v-if="updatingUserReq === user.id" class="spinner" />
                  <span v-else>Accept</span>
                </ion-button>
                <ion-button
                  color="dark"
                  class="ml-2 approve-btn"
                  :disabled="isUserReqUpdating"
                  @click="declineUserRequest(user.id)"
                >
                  <ChLoading size="sm" v-if="updatingUserReq === user.id" class="spinner" />
                  <span v-else>Decline</span>
                </ion-button>
              </div>
            </div>

            <!-- TODO: Show user characters count -->
            <!-- <div class="username">X characters</div> -->

            <div class="description mt-1">
              <i class="msg-inner">"{{ user.message }}"</i>
            </div>
          </div>
        </div>

        <div class="section mt-4">
          <div class="title">Character Requests</div>
          <p v-if="!get(charRequests, 'length')">No pending requests.</p>

          <div v-for="(char, index) in charRequests" :key="index" class="mt-3 px-2">
            <div class="world-card-small d-flex">
              <img loading="lazy" v-image :src="resizeUpload(char.requestee.info.cropProfilePicture, '80x80')" />

              <div class="rest-info ml-3 flex-grow-1 world-info">
                <div class="name d-flex align-items-center justify-content-start">
                  <router-link
                    :to="{
                      name: 'character-profile-new',
                      params: { slug: char.requestee.slug },
                    }"
                    class="text-black clickable-item-hov"
                    >{{ char.requestee.info.name }}</router-link
                  >
                  <p class="small-name ml-1 mb-2">
                    by
                    <router-link
                      :to="{
                        name: 'profile',
                        params: { username: char.requestee.user },
                      }"
                      class="clickable-item-hov"
                      >{{ char.requestee.user }}</router-link
                    >
                  </p>
                </div>

                <div class="description mt-2">
                  {{ truncateText(stripHtmlTags(char.requestee.info.description), 120) }}
                </div>
                <div class="actions mt-2 w-75 d-flex justify-content-start">
                  <ion-button :disabled="isCharReqUpdating" class="approve-btn" @click="approveCharRequest(char.id)">
                    <ChLoading size="sm" v-if="updatingCharReq === char.id" class="spinner" />
                    <span v-else>Accept</span>
                  </ion-button>
                  <ion-button
                    color="dark"
                    class="ml-2 approve-btn"
                    :disabled="isCharReqUpdating"
                    @click="declinecharRequest(char.id)"
                  >
                    <ChLoading size="sm" v-if="updatingCharReq === char.id" class="spinner" />
                    <span v-else>Decline</span>
                  </ion-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section mt-4">
          <div class="title">Team</div>
          <div class="note mt-3 mb-2">
            If you'd like to add another World Leader to your world, please email
            <a href="mailto:sydney@characterhub.com" class="text-black clickable-item-hov link"
              >sydney@characterhub.com</a
            >
            from the email on your account with the username you'd like to add.
          </div>
        </div>

        <div class="section my-4">
          <div class="title">Manage Members ({{ get(worldMembers, 'length', 0) }})</div>

          <div class="note my-3 mb-2">
            Need to remove a character or member? Email
            <a href="mailto:sydney@characterhub.com" class="text-black clickable-item-hov link"
              >sydney@characterhub.com</a
            >
            with the character or the member.
          </div>

          <div v-for="(member, index) in worldMembers" :key="index">
            <div class="d-flex align-items-center">
              <router-link
                :to="{
                  name: 'profile',
                  params: { username: member.user.username },
                }"
                class="clickable-item-hov"
              >
                {{ member.user.username }}
                <span v-if="member.role === worldMemberRoles.leader">({{ worldMemberRoleTitles[member.role] }})</span>
              </router-link>
              <div v-if="loggedInUser.id !== member.user.id" class="ml-auto d-flex">
                <a
                  href="#"
                  class="text-primary d-flex align-items-center mr-3"
                  @click.prevent="updateMemberRole(member.id, member.user.username, member.role)"
                >
                  <i color="primary" class="ti-user mr-1" />
                  <span v-if="member.role == 'member'">Make leader</span>
                  <span v-else-if="member.role == 'leader'">Make member</span>
                </a>
                <a
                  href="#"
                  class="text-danger d-flex align-items-center"
                  @click.prevent="removeMember(member.id, member.user.username)"
                >
                  <i color="danger" class="ti-close mr-1" />
                  <span>Remove</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 d-flex justify-content-center align-items-center flex-wrap">
          <ion-button class="action ml-2" color="danger" @click="deleteWorld()"> Delete World </ion-button>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { alertController } from '@ionic/vue';
import WorldHeader from './WorldHeader.vue';
import { stripHtmlTags } from '@/shared/utils/string';
import { Paging, WorldsInfo } from '@/shared/types/static-types';
import {
  approveWorldRequest,
  declineWorldRequest,
  getWorld,
  getWorldMembers,
  removeWorldMember,
  deleteWorld,
  updateWorldMemberRole,
} from '@/shared/actions/worlds';
import { toast } from '@/shared/native';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';

@Options({
  name: 'ManageWorldMembers',
  components: { WorldHeader },
})
export default class ManageWorldMembers extends Vue {
  get = get;
  stripHtmlTags = stripHtmlTags;
  isEmpty = isEmpty;
  resizeUpload = resizeUpload;
  truncateText = truncateText;

  public paging: Paging | null = null;
  public userRequests: any[] = [];
  public charRequests: any[] = [];
  public worldMembers: any[] = [];
  public countChar = '';
  public status: any = {};
  public checkReq = false;
  public world: WorldsInfo | {} = {};
  public get windowWidth() {
    return window.innerWidth;
  }

  public worldMemberRoles = constants.worldMemberRoles;
  public worldMemberRoleTitles = constants.worldMemberRoleTitles;

  public isUserReqUpdating = false;
  public isCharReqUpdating = false;
  public updatingCharReq: null | string = null;
  public updatingUserReq: null | string = null;

  public get loggedInUser() {
    const { user } = authStore();
    return user.value;
  }

  public async fetchUserRequest() {
    try {
      // let response;
      // response = await getWorldUserCharacterRequest({
      //   world_id: this.$route.params.id,
      //   requestee_type: 'uuiduser',
      // });
      // this.userRequests = response;
    } catch (e) {
      await toast.show('Could not load userlisting. Please try again.', 'nonative', 'danger');
    }
  }

  public async fetchCharacterRequest() {
    try {
      let response;
      // response = await getWorldUserCharacterRequest({
      //   world_id: this.$route.params.id,
      //   requestee_type: 'character',
      // });

      // this.charRequests = response;
    } catch (e) {
      await toast.show('Could not load userlisting. Please try again.', 'nonative', 'danger');
    }
  }

  public async fetchWorldMembers() {
    const router = useRouter();
    try {
      let response;
      response = await getWorldMembers({
        world_id: router.currentRoute.value.params.id as string,
      });

      this.worldMembers = response;
    } catch (e) {
      await toast.show('Could not load userlisting. Please try again.', 'nonative', 'danger');
    }
  }

  public async updateMemberRole(id: string, name: string, currentrole: string) {
    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to make  <b>${name}</b> a <b>${
          currentrole == 'member' ? 'leader' : 'member'
        }</b>.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-primary',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'ok') {
        await updateWorldMemberRole(id, currentrole == 'member' ? 'leader' : 'member');
        this.fetchWorldMembers();
        toast.show('Role updated successfully', 'nonative', 'success');
      }
    } catch (e) {
      toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
    }
  }

  public async removeMember(id: string, name: string) {
    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to remove <b>${name}</b> from this world.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-danger',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'ok') {
        await removeWorldMember(id);
        this.fetchWorldMembers();
        toast.show('Member removed successfully', 'nonative', 'success');
      }
    } catch (e) {
      toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
    }
  }

  public async approveUserRequest(id: string) {
    const router = useRouter();
    if (this.isUserReqUpdating) return;

    this.isUserReqUpdating = true;
    this.updatingUserReq = id;
    try {
      await approveWorldRequest({
        id,
        world: router.currentRoute.value.params.id as string,
      });
      this.userRequests = this.userRequests.filter((user) => user.id !== id);
      await toast.show('Request approved', 'nonative', 'success');
      this.fetchWorldMembers();
    } catch (e) {
      await toast.show('Could not approve this user. Please try again.', 'nonative', 'danger');
    }

    this.updatingUserReq = null;
    this.isUserReqUpdating = false;
  }

  public async deleteWorld() {
    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to delete this world. You will not be able to recover it later.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-danger',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      const router = useRouter();
      if (role === 'ok') {
        await deleteWorld(router.currentRoute.value.params.id as string);
        toast.show('World deleted successfully', 'nonative', 'success');
        router.push({ name: 'worlds' });
      }
    } catch (e) {
      toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
    }
  }

  public async approveCharRequest(id: string) {
    const router = useRouter();
    if (this.isCharReqUpdating) return;

    this.isCharReqUpdating = true;
    this.updatingCharReq = id;
    try {
      await approveWorldRequest({
        id,
        world: router.currentRoute.value.params.id,
      });
      this.charRequests = this.charRequests.filter((char) => char.id !== id);
      await toast.show('Request approved', 'nonative', 'success');
    } catch (e) {
      await toast.show('Could not approve this character. Please try again.', 'nonative', 'danger');
    }
    this.updatingCharReq = null;
    this.isCharReqUpdating = false;
  }

  public async declineUserRequest(id: string) {
    await declineWorldRequest(id);
    this.userRequests = this.userRequests.filter((user) => user.id !== id);
  }

  public async declinecharRequest(id: string) {
    await declineWorldRequest(id);
    this.charRequests = this.charRequests.filter((char) => char.id !== id);
  }

  public async fetchWorld() {
    const router = useRouter();
    this.world = await getWorld(router.currentRoute.value.params.id as string);
  }

  async mounted() {
    const router = useRouter();
    try {
      await this.fetchWorld();
      this.fetchUserRequest();

      this.fetchCharacterRequest();
      this.fetchWorldMembers();
    } catch (_e) {
      router.push({ name: 'worlds' });
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold
.wrapper
  background-color: #FFF
  width: 100%

.note
  font-size: 16px

@media(max-width: 500px)
  .wrapper
    padding: 0 15px
    position: absolute

.title
  font-size: 24px
  font-weight: bold
.username
  font-size: 16px
  font-weight: bold

.name
  font-size: 18px
  font-weight: bold

.actions
  max-width: 200px
  .inline-button
    &:not(:first-of-type)
      margin: 0 5px

.world-card-small
  img
    width: 76px
    height: 76px
    border-radius: 40px
    border: solid gray 0.1px
    object-fit: cover
  .name
    font-size: 20px
    font-weight: bold

  .small-name
    font-size: 12px
  .description
    font-size: 14px
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
      color: #3dc2ff
  .reaction-count
    font-size: 18px
    font-weight: bold

.routerlink
  color: #214163

.world-info
  width: calc(100% - 93px)

.approve-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px
.msg-inner
  white-space: pre-line
</style>
